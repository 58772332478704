import Swiper from 'swiper';

export default () => new Swiper('.js-swiper', {
    watchSlidesVisibility: true,
    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: 16,
    breakpoints: {
        576: {
            centeredSlides: false,
        },
    },
});
