class TeamMemberModal {
    constructor(trigger) {
        if (!trigger) return;

        this.modal = trigger.parentElement.parentElement.querySelector('.js-team-member-modal');
        if (!this.modal) return;

        trigger.addEventListener('click', this.handleClick.bind(this));

        const closingTriggers = this.modal.querySelectorAll('.js-team-modal-closing-trigger');
        closingTriggers.forEach((closingTrigger) => {
            closingTrigger.addEventListener('click', this.handleClick.bind(this));
        });
    }

    handleClick(event) {
        event.preventDefault();
        this.modal.classList.toggle('hidden');
        document.body.classList.toggle('modal-is-open');
    }
}

export default () => {
    const triggers = document.querySelectorAll('.js-team-modal-trigger');
    if (!triggers) return false;

    return Array.from(triggers).map(trigger => new TeamMemberModal(trigger));
};
