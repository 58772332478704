class SmoothAnchorScroll {
    constructor() {
        const anchors = document.querySelectorAll('.js-click-scroll-anchor');
        anchors.forEach((item) => {
            item.addEventListener('click', SmoothAnchorScroll.handleClick);
        });

        if (window.location.hash) {
            return SmoothAnchorScroll.scrollToAnchor(window.location.hash);
        }

        const manualAnchor = document.querySelector('#js-manual-scroll-anchor');
        if (manualAnchor) {
            SmoothAnchorScroll.scrollToAnchor('#js-manual-scroll-anchor', false);
        }
    }

    static handleClick(event) {
        event.preventDefault();

        const hash = SmoothAnchorScroll.getHashFromUrlString(event.currentTarget.href);
        SmoothAnchorScroll.addHashToUrl(hash);
        SmoothAnchorScroll.scrollToAnchor(hash);
    }

    static addHashToUrl(hash) {
        window.history.pushState(null, null, hash);
    }

    static scrollToAnchor(hash, animate = true) {
        const anchor = document.querySelector(hash);
        if (!anchor) return;

        const {top} = anchor.getBoundingClientRect();
        window.scrollTo({
            top: top + window.scrollY,
            behavior: animate ? 'smooth' : 'auto',
        });
    }

    static getHashFromUrlString(url) {
        return url.substring(url.indexOf('#'));
    }
}

export default () => new SmoothAnchorScroll();
