class TextExcerpt {
    constructor(element) {
        if (!element) return;

        const trigger = element.querySelector('.js-text-excerpt-trigger');
        trigger.addEventListener('click', (event) => {
            event.preventDefault();
            element.classList.add('is-open');
        });
    }
}

export default () => {
    const elements = document.querySelectorAll('.js-text-excerpt');
    if (!elements) return false;

    return Array.from(elements).map(element => new TextExcerpt(element));
};
