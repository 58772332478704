import lottie from 'lottie-web';

export default () => {
    if (!document.querySelector('.js-cross-animation')) return;

    lottie.loadAnimation({
        container: document.querySelector('.js-cross-animation'),
        loop: true,
        autoplay: true,
        path: '/wp-content/themes/crossbuilders/cross-animation.json',
        rendererSettings: {
            scaleMode: 'noScale',
        },
    });
};
