import mobileMenuToggle from './modules/mobileMenuToggle';
import accordion from './modules/accordion';
import smoothAnchorScroll from './modules/smoothAnchorScroll';
import crossAnimation from './modules/crossAnimation';
import textExcerpts from './modules/textExcerpts';
import teamMemberModal from './modules/teamMemberModal';
import universeBubbles from './modules/universeBubbles';
import swiper from './modules/swiper';

window.onload = () => {
    mobileMenuToggle();
    accordion();
    smoothAnchorScroll();
    crossAnimation();
    textExcerpts();
    teamMemberModal();
    universeBubbles();
    swiper();
};
