const ACTIVE_CLASS = 'accordion-item-active';

/* eslint-disable no-param-reassign */
class Accordion {
    constructor(element) {
        this.element = element;
        this.items = element.querySelectorAll('.js-accordion-item');
        this.imageElements = document.querySelectorAll('.js-accordion-image');
        if (!this.items) return;

        this.items.forEach((item) => {
            item.addEventListener('click', () => {
                this.toggleItem(item);
            });
        });
    }

    toggleItem(item) {
        if (item.classList.contains(ACTIVE_CLASS)) return;

        this.closeAllItems();
        item.classList.toggle(ACTIVE_CLASS);
        this.updateImages(item);
    }

    updateImages(item) {
        const {imageUrl} = item.dataset;
        if (this.imageElements && imageUrl) {
            this.imageElements.forEach((image) => {
                image.src = imageUrl;
            });
        }
    }

    closeAllItems() {
        this.items.forEach(item => item.classList.remove(ACTIVE_CLASS));
    }
}

export default () => {
    const elements = document.querySelectorAll('.js-accordion');
    if (!elements) return false;

    return Array.from(elements).map(element => new Accordion(element));
};
