import tippy from 'tippy.js';

class UniverseBubbles {
    constructor(element) {
        this.element = element;
        this.bubbles = element.querySelectorAll('.bubble');

        this.bubbles.forEach((bubble) => {
            if (!bubble.dataset || !bubble.dataset.tooltipLabel || !bubble.dataset.tooltipText) return;

            const content = document.createElement('div');
            content.innerHTML = `<h3 class="headline-3">${bubble.dataset.tooltipLabel}</h3>`;
            content.innerHTML += `${bubble.dataset.tooltipText}`;
            content.innerHTML += '<button class="tippy-box__close js-tippy-close">X</button>';

            const instance = tippy(bubble, {
                content,
                arrow: true,
                interactive: true,
                appendTo: document.body,
                theme: 'crossbuilders',
                trigger: 'click',
            });

            instance.popper.querySelector('.js-tippy-close').addEventListener('click', () => {
                instance.hide();
            });
        });
    }

    static toggleTooltip(bubble) {
        if (!bubble.dataset || !bubble.dataset.tooltipLabel || !bubble.dataset.tooltipText) return;

        tippy(bubble, {
            content: bubble.dataset.tooltipText,
            trigger: 'click',
        });
    }
}

export default () => {
    const containers = document.querySelectorAll('.js-universe-svg');
    containers.forEach(container => new UniverseBubbles(container));
};
